<template>
  <div class="login-page page register-page">
    <p class="login-title">
      河南联创融久
    </p>
    <p class="login-title-less">
      供应链管理系统
    </p>
    <van-form class="login-form" @submit="register">
      <p class="form-label">
        企业统一社会信用代码：
      </p>
      <van-field v-model="registerForm.orgNo" :rules="rules.orgNo" label="" placeholder="请输入企业统一社会信用代码" />
      <p class="form-label">
        管理员手机号码：
      </p>
      <van-field v-model="registerForm.phone" :rules="rules.phone" label="" placeholder="请输入管理员手机号码" />
      <p class="form-label">
        短信验证码：
      </p>
      <van-field
        v-model="registerForm.verificationCode"
        center
        clearable
        label=""
        :rules="rules.verificationCode"
        placeholder="请输入短信验证码"
      >
        <template #button>
          <van-button size="small" type="primary" :disabled="isDisabled" native-type="button" @click="send()">
            {{ codeTip }}
          </van-button>
        </template>
      </van-field>
      <van-checkbox v-model="checked" shape="square">
        我已阅读同意《用户协议》
      </van-checkbox>
      <van-button block type="info" native-type="submit" :disabled="!checked" :loading="loading.register">
        注 册
      </van-button>
    </van-form>
    <p class="register-already" @click="$router.push({path:'/login'})">
      使用已有账户登录
    </p>
    <p class="examine-title">
      {{ constants.examineTitle }}
    </p>
  </div>
</template>

<script>
import { Field, Form, Button, Toast, NoticeBar, Checkbox } from 'vant'
export default {
  components: {
    [Field.name]: Field,
    [Form.name]: Form,
    [Button.name]: Button,
    [NoticeBar.name]: NoticeBar,
    [Checkbox.name]: Checkbox
  },
  data () {
    return {
      registerForm: {
        orgNo: '',
        phone: '',
        verificationCode: ''
      },
      loading: {
        register: false
      },
      checked: false,
      time: 60,
      codeTip: '发送验证码',
      isDisabled: false,
      rules: {
        orgNo: [
          { required: true, message: '请输入企业统一社会信用代码' },
          { pattern: this.constants.rulesExclude50, message: this.constants.rulesToolkit50 }
        ],
        phone: [
          { required: true, message: '请输入管理员手机号码' },
          { pattern: this.constants.rulesExcludePhone, message: this.constants.rulesToolkitPhone }
        ],
        verificationCode: [
          { required: true, message: '请输入短信验证码' },
          { pattern: this.constants.rulesInteger4, message: this.constants.rulesToolkitInteger4 }
        ]
      }
    }
  },
  created () {
  },
  methods: {
    // 注册
    register () {
      this.loading.register = true
      this.api.base.authReg(this.registerForm).then(result => {
        const data = result.data.value
        Toast.success('注册成功')
        this.login(data)
      }).finally(() => {
        this.loading.register = false
      })
    },
    login (data) {
      this.$store.commit('removeEmpInfo')
      const form = {
        client_id: 'c1',
        client_secret: 'secret',
        grant_type: 'password',
        username: data.mobile,
        password: '123456'
      }
      this.api.base.goLogin(form).then(result => {
        const data = result.data.data
        localStorage.setItem('token', data.access_token)
        localStorage.setItem('refreshToken', data.refresh_token.value)
        localStorage.setItem('tokenType', this._.upperFirst(data.token_type))
        this.$store.commit('updateEmpInfo')
        // 个人信息
        localStorage.setItem('employeeInfo', JSON.stringify(data.userInfo || {}))
        this.$store.commit('updateEmployeeInfo')
        this.$router.push({ path: '/personal/password', query: { userName: data.userInfo.userName, enterpriseId: data.userInfo.enterpriseId, userId: data.userInfo.id, realName: data.userInfo.realName } })
      }).finally((e) => {
      })
    },
    send () {
      if (!this.registerForm.phone) {
        Toast.fail('请输入管理员手机号码')
        return false
      }
      if (!this.constants.rulesExcludePhone.test(this.registerForm.phone)) {
        Toast.fail('请输入正确手机号码')
        return false
      }
      this.api.base.sendVerificationSms({ phone: this.registerForm.phone, type: 1 }).then(result => {
        // const data = result.data.data
        // this.registerForm.verificationCode = data
        Toast.success('发送成功')
        var timeStop = setInterval(() => {
          this.time = this.time - 1
          if (this.time > 0) {
            this.codeTip = '重新发送' + this.time + 's'
            this.isDisabled = true
          } else {
            this.time = 60
            this.codeTip = '发送验证码'
            clearInterval(timeStop)
            this.isDisabled = false
          }
        }, 1000)
      }).finally(() => {
      })
    }
  }
}
</script>

<style>

</style>
