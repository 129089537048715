var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-page page register-page" },
    [
      _c("p", { staticClass: "login-title" }, [_vm._v(" 河南联创融久 ")]),
      _c("p", { staticClass: "login-title-less" }, [
        _vm._v(" 供应链管理系统 ")
      ]),
      _c(
        "van-form",
        { staticClass: "login-form", on: { submit: _vm.register } },
        [
          _c("p", { staticClass: "form-label" }, [
            _vm._v(" 企业统一社会信用代码： ")
          ]),
          _c("van-field", {
            attrs: {
              rules: _vm.rules.orgNo,
              label: "",
              placeholder: "请输入企业统一社会信用代码"
            },
            model: {
              value: _vm.registerForm.orgNo,
              callback: function($$v) {
                _vm.$set(_vm.registerForm, "orgNo", $$v)
              },
              expression: "registerForm.orgNo"
            }
          }),
          _c("p", { staticClass: "form-label" }, [
            _vm._v(" 管理员手机号码： ")
          ]),
          _c("van-field", {
            attrs: {
              rules: _vm.rules.phone,
              label: "",
              placeholder: "请输入管理员手机号码"
            },
            model: {
              value: _vm.registerForm.phone,
              callback: function($$v) {
                _vm.$set(_vm.registerForm, "phone", $$v)
              },
              expression: "registerForm.phone"
            }
          }),
          _c("p", { staticClass: "form-label" }, [_vm._v(" 短信验证码： ")]),
          _c("van-field", {
            attrs: {
              center: "",
              clearable: "",
              label: "",
              rules: _vm.rules.verificationCode,
              placeholder: "请输入短信验证码"
            },
            scopedSlots: _vm._u([
              {
                key: "button",
                fn: function() {
                  return [
                    _c(
                      "van-button",
                      {
                        attrs: {
                          size: "small",
                          type: "primary",
                          disabled: _vm.isDisabled,
                          "native-type": "button"
                        },
                        on: {
                          click: function($event) {
                            return _vm.send()
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.codeTip) + " ")]
                    )
                  ]
                },
                proxy: true
              }
            ]),
            model: {
              value: _vm.registerForm.verificationCode,
              callback: function($$v) {
                _vm.$set(_vm.registerForm, "verificationCode", $$v)
              },
              expression: "registerForm.verificationCode"
            }
          }),
          _c(
            "van-checkbox",
            {
              attrs: { shape: "square" },
              model: {
                value: _vm.checked,
                callback: function($$v) {
                  _vm.checked = $$v
                },
                expression: "checked"
              }
            },
            [_vm._v(" 我已阅读同意《用户协议》 ")]
          ),
          _c(
            "van-button",
            {
              attrs: {
                block: "",
                type: "info",
                "native-type": "submit",
                disabled: !_vm.checked,
                loading: _vm.loading.register
              }
            },
            [_vm._v(" 注 册 ")]
          )
        ],
        1
      ),
      _c(
        "p",
        {
          staticClass: "register-already",
          on: {
            click: function($event) {
              return _vm.$router.push({ path: "/login" })
            }
          }
        },
        [_vm._v(" 使用已有账户登录 ")]
      ),
      _c("p", { staticClass: "examine-title" }, [
        _vm._v(" " + _vm._s(_vm.constants.examineTitle) + " ")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }